<template>
  <div class="tabs-num-depot-manquant">
    <b-table
      responsive
      show-empty
      id="my-table"
      class="custom-table-style custom-table-rapport-style custom-table-doublent"
      :items="getNumDepotVideTh"
      :fields="fields"
      sticky-header
      bordered
      hover
      head-variant="light"
      empty-text="Il n'y a aucun enregistrement à afficher"
    >
      <template v-slot:cell(numero_dossier)="data">
        {{ data.item.numero_dossier }}
      </template>
      <template v-slot:cell(installeur)="data">
        {{ data.item.installeur }}
      </template>
      <template v-slot:cell(numero_de_depot)="data">
        {{ data.item.numero_de_depot }}
      </template>

      <template v-slot:cell(nom1)="data">
        {{ data.item.nom1 }}{{ data.item.prenom1 }}
      </template>
      <template v-slot:cell(depot)="data">
        {{ data.item.depot }}
        <EditableInput
          :editable="data.item.depot != null ? false : true"
          champName="depot"
          editableType="select"
          :optionsSelect="computedGetALLDepotForYearTh"
          :item="data.item"
          :value="data.item.depot"
          type="text"
          :updateFunction="update"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    annee: { require: true },
    update: { required: false }
  },
  data() {
    return {
      fields: [
        {
          key: 'numero_dossier',
          label: 'Numéro dossier'
        },

        {
          key: 'nom1',
          label: 'Nom & prénom'
        },
        {
          key: 'installeur',
          label: 'Installeur'
        },
        {
          key: 'numero_de_depot',
          label: 'Numéro de dépôt'
        },
        {
          key: 'depot',
          label: 'Dépôt'
        }
      ]
    };
  },
  methods: {
    ...mapActions(['fetchNumDepotVideTh']),
    DeleteDoublent(payload) {
      this.deleteProjet({ item: payload, type: this.type });
    }
  },
  computed: {
    ...mapGetters(['getNumDepotVideTh', 'getAllDepotForYearTh']),
    computedGetALLDepotForYearTh() {
      return this.getAllDepotForYearTh.map(item => ({
        full_name: item.depot,
        ...item
      }));
    }
  },
  mounted() {
    if (this.annee != null) {
      this.fetchNumDepotVideTh({
        annee: this.annee
      });
    }
  },
  watch: {
    annee: function() {
      if (this.annee != null) {
        this.fetchNumDepotVideTh({
          annee: this.annee
        });
      }
    }
  },
  components: {
    EditableInput: () => import('./EditableInput')
  }
};
</script>

<style lang="scss">
.tabs-num-depot-manquant {
  height: 100%;
}
.custom-table-doublent {
  max-height: calc(100vh - 197px);
  height: calc(100vh - 197px);
}
</style>
